import { React, useState } from "react";

import { db } from "../firebase.js";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Fade,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import BrandLogo from "./brandLogo.jsx";
import CheckIcon from "@mui/icons-material/Check";

const BusinessFeedbackRequestPage = () => {
  const [docID, setDocID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [errors, setErrors] = useState({});
  const [confirmEmail, setConfirmEmail] = useState("");
  const [docIdError, setDocIdError] = useState("");
  const [frequency, setFrequency] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required";
    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is invalid";
    }

    if (!confirmEmail.trim()) {
      newErrors.confirmEmail = "Confirm Email is required";
    } else if (email !== confirmEmail) {
      newErrors.confirmEmail = "Emails do not match";
    }

    if (!question.trim()) newErrors.question = "Question is required";

    if (!/^[a-z-_]+$/.test(docID)) {
      newErrors.docID = "Doc ID must be all lowercase and contain no spaces";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const docRef = doc(db, "business_feedback_request", docID);

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDocIdError(
            "This document ID is already in use. Please choose another."
          );
          return;
        }

        setDocIdError("");

        await setDoc(docRef, {
          create_time: new Date().toISOString(),
          docID,
          name,
          email,
          question,
          frequency,
        });

        console.log("Document written with ID: ", docID);

        // Reset form fields
        setDocID("");
        setName("");
        setEmail("");
        setQuestion("");
        setConfirmEmail("");
        setErrors({});
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#F8F6F4",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        alt="Brand logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          width: "40px",
          height: "40px",
        }}
      >
        <Link to="/">
          <BrandLogo
            sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
      </Box>

      <Fade in={true} timeout={700}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            color="black"
            sx={{
              marginBottom: 2,
              fontWeight: "700",
              fontSize: { xs: "1.4rem", md: "1.8rem" },
              color: "red",
            }}
          >
            Create Business Feedback Request
          </Typography>

          <form onSubmit={handleSubmit} noValidate>
            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Doc ID"
                fullWidth
                value={docID}
                onChange={(e) => setDocID(e.target.value)}
                error={!!errors.docID}
                helperText={errors.docID}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
              {docIdError && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {docIdError}
                </Typography>
              )}
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Email"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Confirm Email"
                fullWidth
                type="email"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                error={!!errors.confirmEmail}
                helperText={errors.confirmEmail}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Question"
                fullWidth
                multiline
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                error={!!errors.question}
                helperText={errors.question}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Frequency</InputLabel>
                <Select
                  value={frequency}
                  onChange={handleFrequencyChange}
                  label="Frequency"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "10px", // Rounded corners for dropdown
                      },
                    },
                  }}
                >
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button
              type="submit"
              variant="contained"
              sx={{
                padding: 1.5,
                borderRadius: "50px",
                textTransform: "none",
                width: "100px",
                backgroundColor: "primary", // Set custom background color
                color: "white",
              }}
            >
              <CheckIcon />
            </Button>
          </form>
        </Container>
      </Fade>
    </Box>
  );
};

export default BusinessFeedbackRequestPage;
