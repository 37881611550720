import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import BrandLogo from "./brandLogo.jsx";
import { Link } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export default function Header() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 5,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Link to="/">
              <BrandLogo
                sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
              />
            </Link>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => navigate(`/request`)}
                sx={{ textTransform: "none" }}
              >
                Request Feedback
              </Button>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() =>
                  (window.location.href = "https://buymeacoffee.com/mtip")
                }
                sx={{ textTransform: "none" }}
              >
                Support
              </Button>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() =>
                  (window.location.href = "mailto:tonyipmt@gmail.com")
                }
                sx={{ textTransform: "none" }}
              >
                Contact
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => navigate(`/`)}>Home</MenuItem>
                <MenuItem onClick={() => navigate(`/request`)}>
                  Request feedback
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    (window.location.href = "https://buymeacoffee.com/mtip")
                  }
                >
                  Support us
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    (window.location.href = "mailto:tonyipmt@gmail.com")
                  }
                >
                  Contact us
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
